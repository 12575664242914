<template>
  <div class="masquerade">
    <span>
      Admin Logged in as <strong>{{ userEmailCookie }}</strong>
    </span>

    <ZButton
      link
      variant="white"
      size="sm"
      @click="returnToOpsDashboard"
    >
      Quit masquerading
    </ZButton>
  </div>
</template>

<script setup lang="ts">
import { COOKIES } from '~/constants'

const runtimeConfig = useRuntimeConfig()
const { isLoggedIn, logOut } = useAuthentication()
const userEmailCookie = useCookie(COOKIES.userEmail)

const accessTokenCookie = useCookie(COOKIES.accessToken, {
  domain: runtimeConfig.public.cookieDomain,
})
const adminAccessTokenCookie = useCookie(COOKIES.adminAccessToken, {
  domain: runtimeConfig.public.cookieDomain,
})

// You shouldn't masquerade yourself
if (accessTokenCookie.value === adminAccessTokenCookie.value) {
  clearMasqueradeCookies()
}

function clearMasqueradeCookies() {
  adminAccessTokenCookie.value = null
  userEmailCookie.value = null
}

async function returnToOpsDashboard() {
  if (isLoggedIn.value && Boolean(adminAccessTokenCookie.value)) {
    clearMasqueradeCookies()
    await logOut({ callbackUrl: runtimeConfig.public.apps.operationsDashboard.baseURL, external: true })
  }
}
</script>

<style lang="scss" scoped>
.masquerade {
  background-color: getColor('highlight-500');
  color: #fff;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  position: sticky;
  top: 0;
  z-index: $headerZindex;
}
</style>
